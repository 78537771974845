const SmoothScroll = require('smooth-scroll');

// Smooth scroll behaviro for the menu links
new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]',
  easing: 'easeInOutCubic',
  speed: 2000,
  topOnEmptyHash: true,
  updateURL: true,
});


// Listener for applying a class to the navbar on scroll
window.addEventListener('scroll', event => {
  const menu = document.getElementById('menu'); 
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if(scrollPosY > 50) {
    menu.classList.add(`scrolling`);
  } else if(scrollPosY <= 100) {
    menu.classList.remove(`scrolling`);
  }
});


// Custom JS

